import Vue from "vue";
import VueRouter from "vue-router";

// 解决在当前路由上点击自身路由报错的问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/Index",
  },
  {
    path: "/index",
    component: () => import("../components/Index.vue"),
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../components/Home.vue"),
      },
      {
        path: "about",
        name: "about",
        component: () => import("../components/about/About.vue"),
        children: [
          {
            path: "aboutUs",
            name: "aboutUs",
            component: () => import("../components/about/AboutUs.vue"),
          },
          {
            path: "aboutHistory",
            name: "aboutHistory",
            component: () => import("../components/about/AboutHistory.vue"),
          },
          {
            path: "aboutStructure",
            name: "aboutStructure",
            component: () => import("../components/about/AboutStructure.vue"),
          },
          {
            path: "aboutEmpStyle",
            name: "aboutEmpStyle",
            component: () => import("../components/about/AboutEmpStyle.vue"),
          },
          {
            path: "aboutContactUs",
            name: "aboutContactUs",
            component: () => import("../components/about/AboutContactUs.vue"),
          },
        ],
      },
      {
        path: "prodCenter",
        name: "prodCenter",
        component: () => import("../components/ProdCenter.vue"),
      },
      {
        path: "typicalCase",
        name: "typicalCase",
        component: () => import("../components/typicalCase/TypicalCase.vue"),
        meta: {
          title: "典型案例",
        },
        children: [
          {
            path: "demoDetails",
            name: "demoDetails",
            meta: {
              title: "案例详情",
            },
            component: () =>
              import("../components/typicalCase/DemoDetails.vue"),
          },
          {
            path: "brewInfo",
            name: "brewInfo",
            meta: {
              title: "案例详情",
            },
            component: () => import("../components/typicalCase/brewInfo.vue"),
          },
          {
            path: "egrainInfo",
            name: "egrainInfo",
            meta: {
              title: "案例详情",
            },
            component: () => import("../components/typicalCase/egrainInfo.vue"),
          },
          {
            path: "appInfo",
            name: "appInfo",
            meta: {
              title: "案例详情",
            },
            component: () => import("../components/typicalCase/appInfo.vue"),
          },
        ],
      },

      {
        path: "newsCenter",
        name: "newsCenter",
        component: () => import("../components/news/NewsCenter.vue"),
        children: [
          {
            path: "infomation",
            name: "infomation",
            component: () => import("../components/news/Infomation.vue"),
          },
          {
            path: "newCenterDetails",
            name: "newCenterDetails",
            component: () => import("../components/news/NewsCenterDetails.vue"),
          },
          {
            path: "dynamic",
            name: "dynamic",
            component: () => import("../components/news/Dynamic.vue"),
          },
        ],
      },{
        path: "solution",
        name: "solution",
        component: () => import("../components/solution/solutionHome.vue"),

      },
      {
        path: "talentDevelopment",
        name: "talentDevelopment",
        meta: {
          title: "人才发展",
        },
        component: () => import("../components/talent/TalentDevelopment.vue"),
        children: [
          {
            path: "talentDevDetails",
            name: "talentDevDetails",
            meta: {
              title: "培训介绍详情",
            },
            component: () =>
              import("../components/talent/TalentDevDetails.vue"),
          },
        ],
      },
      {
        path: "recruitmentCenter",
        name: "recruitmentCenter",
        component: () =>
          import("../components/recruitment/RecruitmentCenter.vue"),
        children: [
          {
            path: "campus",
            name: "campus",
            component: () => import("../components/recruitment/Campus.vue"),
          },
          {
            path: "social",
            name: "social",
            component: () => import("../components/recruitment/Social.vue"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
