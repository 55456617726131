import Vue from "vue";

import Antd from "ant-design-vue";

import VueAMap from "vue-amap";

// 引入animate.css
import animated from "animate.css";

import "./assets/css/swiper.css";

import App from "./App.vue";
import "ant-design-vue/dist/antd.css";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(VueAMap);
Vue.use(animated);
// 初始化高德地图的key和插件
VueAMap.initAMapApiLoader({
  key: "e47b73f4951fd06e641931de42e9b473",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
  ],
  v: "1.4.4",
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
